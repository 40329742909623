import React, { useState } from 'react';
import { Button, Modal, Container } from 'react-bootstrap';
import ContactForm from './contact-us/ContactForm';
import styles from "./modal.module.css";

const ContactUsModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container>
        <div className={styles.helpContainer}>
          <div className={styles.leftLine}></div>
          <span className={styles.helpText}>For More Help</span>
          <Button className={styles.modalButton} onClick={handleShow}>
            Contact Us
          </Button>
          <div className={styles.rightLine}></div>
        </div>

        <Modal show={show} onHide={handleClose} size="lg" animation={true}>
          <Modal.Header closeButton>
          
          </Modal.Header>
          <Modal.Body closeButton>
            <ContactForm />
          </Modal.Body>
          
        </Modal>
      </Container>
    </>
  );
};

export default ContactUsModal;
