// App.js
import React from 'react';
import {  Route, Routes, Navigate } from 'react-router-dom'; // Use BrowserRouter here
import NavBar from './components/navbar/NavBar';
import Home from './components/Home/Home';
import Radio from './components/Radios/Radio';
import ContactUs from './components/contact-us/ContactUs';
import DetectInfo from './components/ecosystem/DetectInfo';
import AnalyzeInfo from './components/ecosystem/AnalyzeInfo';
import CommunicateInfo from './components/ecosystem/CommunicateInfo';
import ManageInfo from './components/ecosystem/ManageInfo';
import VideoSurv from './components/video/VideoSurv';
import PublicSafety from './components/publicSafety/PublicSafety';
import BodyWorn from './components/BodyWorn/BodyWorn';
import WaveTlk from './components/Wave/WaveTlk';
import Bda from './components/bda/Bda';
import RadioDetail from './components/Radios/RadioDetail';
import Footer from './components/footer/Footer';
import Rental from './components/rentals/Rentals';
import About from './components/about/About';
import 'leaflet/dist/leaflet.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  
  return (
    <div>
      <NavBar />
      <div className='background'>
        <div className='body'>
          
            <Routes>
 

              <Route path="/" element={<Home />} />
              <Route path="/radios" element={<Radio />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/ecosystem-detect" element={<DetectInfo />} />
              <Route path="/ecosystem-analyze" element={<AnalyzeInfo />} />
              <Route path="/ecosystem-communicate" element={<CommunicateInfo />} />
              <Route path="/ecosystem-manage" element={<ManageInfo />} />
              <Route path="/video-surveillance" element={<VideoSurv />} />
              <Route path="/public-safety" element={<PublicSafety />} />
              <Route path="/body-cameras" element={<BodyWorn />} />
              <Route path="/wave-ptx" element={<WaveTlk />} />
              <Route path="/radios/bda" element={<Bda />} />
              <Route path="/radios/:tag" element={<Radio />} />
              <Route path="/rental-radios" element={<Rental />} />
              <Route path="/about" element={<About />} />
              <Route path="/radios/:tag/:name" element={<RadioDetail />} />
              <Route path="/*" element={<Navigate to="/" replace />} />
            </Routes>
          
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
